import styled from "styled-components";

import { white, black } from "theme/colors";

export const Wrapper = styled.header`
  position: fixed;
  width: 100%;
  padding: 20px 0px;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 14;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .col {
    display: flex;
    align-items: center;
    .menu-lang {
      display: flex;
      align-items: center;
      margin-right: 25px;
      li {
        font-family: "Nexa";
        font-weight: 800;
        line-height: normal;
        position: relative;
        font-weight: 300;
        line-height: 14px;
        font-size: 14px;
        color: ${(props) => (props.scolor ? black.default : white.default)};
        a {
          display: block;
          padding: 10px;
          font-size: 14px;
          line-height: 14px;
        }
        &.active {
          font-weight: bold;
        }
      }
    }
  }
  @media (min-width: 769px) {
    padding: 25px 0px;
    .col {
      .menu-lang {
        margin-right: 0;
      }
    }
  }
`;

export const Logo = styled.div`
  position: relative;
  z-index: 16;
  a {
    color: ${(props) => (props.scolor ? black.default : white.default)};
  }
`;

export const Toggle = styled.div`
  position: relative;
  z-index: 50;
  width: 20px;
  height: 16px;
  display: block;
  .span {
    top: 7px;
    background-color: ${(props) =>
      props.scolor ? black.default : white.default};
    width: 20px;
    height: 2px;
    display: block;
    position: absolute;
    &--top {
      position: absolute;
      top: 0px;
    }
    &--bottom {
      position: absolute;
      top: 14px;
    }
  }
  @media (min-width: 769px) {
    display: none;
  }
`;
