import React, { useEffect } from "react";
import { NavColumn, Wrapper } from "./Nav.styles";
import { Link, useStaticQuery, graphql } from "gatsby";

import gsap from "gsap";

const tl = gsap.timeline();

const Nav = (props) => {
  //PROPS
  const {
    scolor,
    lang,
    uri,
    dimensions,
    menuState,
    setMenuState,
    setColor,
  } = props;
  //PROPS

  //ANIMS
  const openMenu = () => {
    tl.to(".span ", {
      duration: 0.22,
      css: { top: 7 },
    })
      .to(".span--top", {
        duration: 0.22,
        rotation: 45,
      })
      .to(".span--bottom", {
        duration: 0.22,
        delay: -0.22,
        rotation: -45,
      })
      .to(".span--center", {
        duration: 0.22,
        delay: -0.22,
        opacity: 0,
      })
      .to(".navContainer", {
        duration: 0.8,
        delay: -0.4,
        translateY: "0",
      });
  };

  const closeMenu = () => {
    tl.to(".span", {
      duration: 0.22,
      rotation: 0,
    })
      .to(".span--top", {
        duration: 0.22,
        css: { top: 0 },
      })
      .to(".span--bottom", {
        duration: 0.22,
        delay: -0.22,
        css: { top: 14 },
      })
      .to(".span--center", {
        duration: 0.22,
        delay: -0.22,
        opacity: 1,
      })
      .to(".navContainer", {
        duration: 0.8,
        delay: -0.44,
        translateY: dimensions.width < 769 ? "-100%" : "",
      });

    if (uri === "/en/" || uri === "/") {
      setColor(false);
    } else {
      setColor(true);
    }
  };

  //ANIMS

  //DATA
  const data = useStaticQuery(graphql`
    query Menu {
      allWpMenu {
        edges {
          node {
            menuItems {
              nodes {
                label
                url
                connectedNode {
                  node {
                    id
                  }
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  const {
    allWpMenu: { edges },
  } = data;
  //DATA

  //DEFS
  let info = edges.filter((item) =>
    item.node.name.search(lang === "EN" ? "Main Menu PT" : "Main Menu EN") !==
    -1
      ? item
      : null
  );
  let menu = info[0].node.menuItems.nodes;
  //DEFS

  //USEFFECTS
  useEffect(() => {
    if (menuState && dimensions.width) {
      openMenu();
    } else if (dimensions.width) {
      closeMenu();
    }
  }, [menuState, dimensions]);

  useEffect(() => {
    setMenuState(false);
  }, [dimensions]);

  useEffect(() => {}, []);
  //USEFFECTS

  return (
    <Wrapper className="navContainer">
      <ul className="menu">
        {menu.map((item, index) => {
          return (
            <NavColumn className="nav-column" key={index} scolor={scolor}>
              {uri + "/" === item.url ? (
                <button onClick={() => setMenuState(false)}>
                  {item.label}
                </button>
              ) : (
                <Link to={item.url ? item.url : "/"}>{item.label}</Link>
              )}
            </NavColumn>
          );
        })}
      </ul>
      {/* <div className="getInTouch">
        {lang === "EN" ? (
          uri + "/" === "/get-in-touch/" ? (
            <button onClick={() => setMenuState(false)}>
              Get in touch +2{" "}
            </button>
          ) : (
            <Link to="/get-in-touch">Get in touch</Link>
          )
        ) : uri + "/" === "/en/get-in-touch/" ? (
          <button onClick={() => setMenuState(false)}>Get in touch + 2</button>
        ) : (
          <Link to="/en/get-in-touch">Get in touch</Link>
        )}
      </div> */}
    </Wrapper>
  );
};

export default Nav;
