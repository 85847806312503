import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import { toRem } from "utils/mixins";
import { black, white } from "./colors";
import { container } from "./sizes";
import { p, h1, h2, h3, h4, fontImport } from "./typography";

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  ${fontImport}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  b {
    font-weight: bold;
  }
  
  html,
  body {
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    background: ${white.default};

  }

  body {
    visibility: hidden;
    opacity: 0;
    overflow-x: hidden;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: ${toRem(20)};
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    color: black;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
    &.noover {
      overflow: hidden;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
  color: black!important;
  border: 1px solid transparent;
}
  

  .main {
    padding-top: ${toRem(100)};
    padding-bottom: ${toRem(25)};
    min-height: 100vh;
    position: relative;

    &--full {
    }
    &--flex {
      display: flex;
    flex-direction: column;
    justify-content: space-between;
    }
    &--nopad { padding-top:0;
    }

    @media (min-width: 1079px) {
      padding-top: ${toRem(150)};
    padding-bottom: ${toRem(50)};
      &--nopad{
        padding-top: 0;
      }
    }
  }

  .container {
    width: 100%;
    max-width: ${container.default};
    margin: 0 auto;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;

    &--small {
      max-width: ${container.small};
    }

    &--800 {
      max-width: ${toRem(800)};
    }

    &--xsmall {
      max-width: ${container.xsmall};
    }

    .smedia__container{
      margin-top:${toRem(8)} ;
      align-items: center;
      justify-content: center;
      display: flex;
      li {
        margin: 0 ${toRem(8)};
        line-height: 10px;
      }
    }

    @media (min-width: 599px) {
      padding-left: 27px;
    padding-right: 27px;
    }
  }

  .content {
    transform: translateY(50px);
    opacity: 0;
    padding-bottom: 60px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 400;
  }

  h1, .h1 {
    ${h1}
    color: black;
    margin:${toRem(12)} 0;
    transition: 250ms;
  }

  h2 {
    ${h2}
    color: black;
    margin: ${toRem(12)} 0;
    transition: 250ms;
  }

  h3 {
    ${h3}
  }

  h4{
    ${h4}
  }

  p, label {
    ${p}
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  a { 
    ${h3};
    position: relative;
    font-weight: 300;
    color: ${white.default};
    color: inherit;
    text-decoration: none;
    transition: 250ms;
    span {
      font-weight: bold;
    }
  }

  button {
    appearance: none;
    display: inline-block;
    border-radius: 0;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
  }

  address {
    font-style: normal;
  }

  p {
    margin-bottom: 1em;
  }

  .show {
    display: block;
  }

  .hidden {
    display: none;
  }

  .hide__mobile{
    @media (max-width: 1079px) {
      display: none;
    }
  }

  .show__mobile{
    display: none;
    @media (max-width: 1079px) {
      display: block;
    }
  }

  .border-left,
  .border-right,
  .border-top,
  .border-bottom {
    background: ${black.default};
    z-index: 900;
    position: fixed;
  }

  .border-left,
  .border-right {
    top: 0;
    bottom: 0;
    width: 7px;
  }
  .border-left {
    left: 0;
  }
  .border-right {
    right: 0;
  }
  .border-top,
  .border-bottom {
    left: 0;
    width: 100%;
    height: 7px;
  }
  .border-top {
    top: 0;
  }
  .border-bottom {
    bottom: 0;
  }

  .cape {  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  height: 0;
  width: 100%;
  background: ${black.default};
  bottom: 0;}
 
  @keyframes animation {
    0% {
      background-color: #f5f5f5;
    }
    20% {
      background-color: #fce300;
    }
    40% {
      background-color: #ffb838;
    }
    60% {
      background-color: #da5959;
    }
    80% {
      background-color: #9185ae;
    }
    100% {
      background-color: #f5f5f5;
    }
  }
  
  @-o-keyframes animation {
    0% {
      background-color: #f5f5f5;
    }
    20% {
      background-color: #fce300;
    }
    40% {
      background-color: #ffb838;
    }
    60% {
      background-color: #da5959;
    }
    80% {
      background-color: #9185ae;
    }
    100% {
      background-color: #f5f5f5;
    }
  }
  @-moz-keyframes animation {
    0% {
      background-color: #f5f5f5;
    }
    20% {
      background-color: #fce300;
    }
    40% {
      background-color: #ffb838;
    }
    60% {
      background-color: #da5959;
    }
    80% {
      background-color: #9185ae;
    }
    100% {
      background-color: #f5f5f5;
    }
  }
  @-webkit-keyframes animation {
    0% {
      background-color: #f5f5f5;
    }
    20% {
      background-color: #fce300;
    }
    40% {
      background-color: #ffb838;
    }
    60% {
      background-color: #da5959;
    }
    80% {
      background-color: #9185ae;
    }
    100% {
      background-color: #f5f5f5;
    }
  }

  @keyframes animationC {
    0% {
      color: #f5f5f5;
    }
    20% {
      color: #fce300;
    }
    40% {
      color: #ffb838;
    }
    60% {
      color: #da5959;
    }
    80% {
      color: #9185ae;
    }
    100% {
      color: #f5f5f5;
    }
  }
  
  @-o-keyframes animationC {
    0% {
      color: #f5f5f5;
    }
    20% {
      color: #fce300;
    }
    40% {
      color: #ffb838;
    }
    60% {
      color: #da5959;
    }
    80% {
      color: #9185ae;
    }
    100% {
      color: #f5f5f5;
    }
  }
  @-moz-keyframes animationC {
    0% {
      color: #f5f5f5;
    }
    20% {
      color: #fce300;
    }
    40% {
      color: #ffb838;
    }
    60% {
      color: #da5959;
    }
    80% {
      color: #9185ae;
    }
    100% {
      color: #f5f5f5;
    }
  }
  @-webkit-keyframes animationC {
    0% {
      color: #f5f5f5;
    }
    20% {
      color: #fce300;
    }
    40% {
      color: #ffb838;
    }
    60% {
      color: #da5959;
    }
    80% {
      color: #9185ae;
    }
    100% {
      color: #f5f5f5;
    }
  }
`;
