// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-custom-a-floresta-template-js": () => import("./../../../src/templates/custom/AFlorestaTemplate.js" /* webpackChunkName: "component---src-templates-custom-a-floresta-template-js" */),
  "component---src-templates-custom-get-in-touch-template-js": () => import("./../../../src/templates/custom/GetInTouchTemplate.js" /* webpackChunkName: "component---src-templates-custom-get-in-touch-template-js" */),
  "component---src-templates-custom-homepage-template-js": () => import("./../../../src/templates/custom/HomepageTemplate.js" /* webpackChunkName: "component---src-templates-custom-homepage-template-js" */),
  "component---src-templates-custom-what-we-do-template-js": () => import("./../../../src/templates/custom/WhatWeDoTemplate.js" /* webpackChunkName: "component---src-templates-custom-what-we-do-template-js" */),
  "component---src-templates-custom-what-weve-done-template-js": () => import("./../../../src/templates/custom/WhatWe'veDoneTemplate.js" /* webpackChunkName: "component---src-templates-custom-what-weve-done-template-js" */),
  "component---src-templates-custom-who-we-are-template-js": () => import("./../../../src/templates/custom/WhoWeAreTemplate.js" /* webpackChunkName: "component---src-templates-custom-who-we-are-template-js" */),
  "component---src-templates-general-post-js": () => import("./../../../src/templates/general/post.js" /* webpackChunkName: "component---src-templates-general-post-js" */)
}

