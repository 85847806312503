import React, { useEffect, useState } from "react";

import gsap from "gsap";
import { AnimatePresence } from "framer-motion";

import { GlobalStyles } from "theme/global";

import Header from "components/header";
import CreateHead from "components/createHeader";
import CookieBar from "components/cookieBar";

//UTILITY
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
//UTILITY

const Layout = ({ location, ...props }) => {
  const {
    pageContext: { translations },
  } = props;

  let locale = props.pageContext?.language?.code;
  //STATE

  const [menuState, setMenuState] = useState(false);
  const [dimensions, setDimension] = useState({
    height: "",
    width: "",
  });
  const [color, setColor] = useState(true);
  const [scrollTop, setScroll] = useState(true);
  const [load, setLoad] = useState(false);
  //STATE

  const tl = gsap.timeline();
  const toTop = () => {
    load &&
      tl.to(`#header`, {
        duration: 0.25,
        css: {
          backgroundColor: "white",
          padding: "24px 0 17px",
        },
      });
  };

  const toBot = () => {
    load &&
      tl.to(`#header`, {
        duration: 0.25,
        css: {
          backgroundColor: "transparent",
          padding: "44px 0 37px",
        },
      });
  };

  useEffect(() => {
    scrollTop ? toBot() : toTop();
  }, [scrollTop]);

  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      scrollY > 100 ? setScroll(false) : setScroll(true);
      ticking = false;

      return;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll, true);

    return () => {
      window.removeEventListener("scroll", onScroll, true);
    };
  }, [location]);

  //EFFECT
  useEffect(() => {
    gsap.to("body", {
      duration: 0,
      css: { visibility: "visible", opacity: 1 },
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const debouncedHandleResize = debounce(function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);

    setDimension({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    setLoad(true);
    return () => {
      toBot();
      setLoad(false);
    };
  }, [location]);
  //EFFECT

  //CHILDREN MODIFIER
  const childrenWithProps = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      dimensions: dimensions,
      color: color,
      setColor: setColor,
    });
  });
  //CHILDREN MODIFIER

  return (
    <>
      <GlobalStyles />

      <CreateHead
        code={locale}
        title={props.pageContext?.seo?.titleSeo}
        image={props.pageContext?.seo?.imageSeo}
        description={props.pageContext?.seo?.descriptionSeo}
      />
      {load && (
        <>
          <Header
            menuState={menuState}
            dimensions={dimensions}
            setMenuState={setMenuState}
            color={color}
            translations={translations}
            location={props.path}
            setColor={setColor}
          />
          <main>
            <AnimatePresence initial={false}>
              {childrenWithProps}
            </AnimatePresence>
          </main>

          <CookieBar translations={translations} />
        </>
      )}
    </>
  );
};

export default Layout;
